import moment from 'moment';
import axios from 'axios';

import Decimal from "decimal.js";

import {
  isMatch,
  parse,
  format,
  isDate,
} from 'date-fns';

import {
  filter,
  keys,
  isEmpty,
  isUndefined,
  toString,
} from 'lodash';

const alphaSort = key => {
  let f = (a, b) => {
    let _a = a[key].toLowerCase();
    let _b = b[key].toLowerCase();
    if (_a < _b) return -1;
    if (_a > _b) return 1;
    return 0;
  };
  return f;
};

// "parent" organisation types
const orgTypeSortOrder = { PHN: 1, POG: 2, ML: 3, DGP: 4 };
const organisationTypeSort = (org) => {
  if ( !org.hasOwnProperty("custodian_organisation_path") ) {
    if ( orgTypeSortOrder.hasOwnProperty( org.organisation_type_code ) ) {
      return orgTypeSortOrder[ org.organisation_type_code ];
    } else {
      return 8;
    }
  }
  return 9;
}

const getSafeValue = (...values) => {
  if (values.length === 1) {
    throw new Error(
      "You need to supply at least 2 arguments for getSafeValue to function."
    );
  }
  for (let _ of values) {
    if (!isUndefined(_)) return _;
  }
  return values.slice(-1)[0];
};

const uniq = (arrArg, key = undefined) => {
  if (!key) {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) == pos;
    });
  } else {
    let keyArr = arrArg.map(k => k[key]);
    return arrArg.filter((e, p, a) => {
      return keyArr.indexOf(e[key]) == p;
    });
  }
};

const titleCase = (input) => {
  input = input || '';
  input = input.replace(/_/g,' ');
  return input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

const entityKeyValid = (key) => {
  return key.match('^[a-zA-Z0-9].{1,49}$') ? true : false;
}

const entityKeyExists = async (path, key, entity) => {
  let url = `/organisations/${encodeRouteParam(path)}`;
  
  if(!key) {
    url += '/exists';
  }
  else {
    url += `/${entity}/${encodeRouteParam(key)}/exists`;
  }
  const api = axios.create({ baseURL: '/api' });
  let resp = await api.get(url);
  return !!resp.data.exists;
};

const encodeRouteParam = value => {
  return value != null
    ? value
        .toString()
        .replace(/~/g,  "~~")
        .replace(/\#/g, "~23")
        .replace(/\//g, "~2F")
        .replace(/\?/g, "~3F")
        .replace(/ /g, "~20")
        .replace(/\x0a/g, "~0A")
        .replace(/\x0d/g, "~0D")
        .replace(/\x09/g, "~09")
    : value;
};

const decodeRouteParam = value => {
  return value != null
    ? value
        .toString()
        .replace(/\~09/g, "\x09")
        .replace(/\~0D/g, "\x0d")
        .replace(/\~0A/g, "\x0a")
        .replace(/\~20/g, " ")
        .replace(/\~3F/g, "?")
        .replace(/\~2F/g, "/")
        .replace(/\~23/g, "#")
        .replace(/~~/g, "~")
    : value;
};

// util functions for vue component
const prepareFilter = (columns, filterParams) => {

  // we take filter arugments and cross-refrence with the column definition
  // to format all dates value correctly
  let definitionHash = { };
  let args = {...filterParams};

  for (let entry of filter(columns, 'form')) {
    const key = entry.form.key;
    const val = entry.form;
    definitionHash[key] = val;
  }

  for (let k of keys(args)) {
    if (definitionHash[k]['isDate']) {
      args[k] = format(parseDate(args[k]), 'yyyy-MM-dd');
    }
  }
  //console.log(args);
  return args;
}

const parseDate = (val) => {
  if (isDate(val)) {
    return val;
  }
  const acceptFormat = ['dd/MM/yyyy', 'd/M/yyyy'];
  for (let entry of acceptFormat) {
    if (isMatch(val, entry)) {
      return parse(val, 'dd/MM/yyyy', new Date());
    }
  }
  return;
}

const validateAbn = (item) => {
  let content = {
    isValid: true,
    msg: '',
  };
  if (isEmpty(item)) {
    return content;
  }
  const value = item.replace(/ /g, '');

  if (!/^\d+$/.test(value)) {
    content.isValid = false;
    content.msg = "Invalid ABN. ABN can contain only numerical digits and spaces.";
    return content;
  }

  if (value.length !== 11) {
    content.isValid = false;
    content.msg = "Invalid ABN. ABN can only be 11 digits long.";
    return content;
  }

  const abnWeightings = [
    10,
    1,
    3,
    5,
    7,
    9,
    11,
    13,
    15,
    17,
    19,
  ];
  const valueArray = value.split('');
  let total = 0;
  valueArray[0]--;
  for (let i = 0; i < valueArray.length; i++) {
    total += parseInt(valueArray[i]) * abnWeightings[i];
  }
  if (total % 89 !== 0) {
    content.isValid = false;
    content.msg = "Invalid ABN.";
    return content;
  }

  return content;
}

const validatePostcode = (val, additionalReg = false) => {
  if (isUndefined(val) || val.length == 0) {
    return true;
  }
  const postreg = new RegExp(
    "^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$"
  );
  const additional = new RegExp("^000[0-9]$");

  if (val === 9999) {
    return true;
  }

  if (additionalReg) {
    // special check for DE activity postcode
    return val.length == 4 && (postreg.test(val) || additional.test(val));
  }

  return val.length == 4 && postreg.test(val);
}

const validateMobile = (val, isAdmin=false) => {
  const ADMIN_REGEX = /^\+?[0-9-.]*$/;
  const MOBILE_REGEX = /^(?:\+61|0)[4|5]\d{8}$/;

  let outcome = { isValid: true };

  if (toString(val).length == 0) {
    return outcome;
  }
  const value = val.replace(/ /g, '');

  // for admin only
  if (isAdmin) {
    outcome.isValid = ADMIN_REGEX.test(value);
    if (!outcome.isValid) {
      outcome.message = "This number is not a valid mobile number (international or otherwise)";
    }
    return outcome;
  }

  // check prefix
  if (value.substring(0, 2) !== "04" && value.substring(0, 2) !== "05" && value.substring(0, 3) !== "+61") {
    outcome.isValid = false;
    outcome.message = "Prefix must be +61, 04 or 05";
    return outcome;
  }

  // check length
  if ((value.length !== 12 && value.substring(0, 3) !== "+61") && value.length !== 10) {
    outcome.isValid = false;
    outcome.message = "Phone number has invalid length."
    return outcome;
  }
  // check valid aus mobile
  outcome.isValid = MOBILE_REGEX.test(value);
  if (!outcome.isValid) {
    outcome.message = "This number is not a valid Australian mobile number";
  }
  return outcome;
}

const validateEmail = (val) => {
  if (toString(val).length == 0) {
    return true;
  }

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(val);
}

const validateDecimal = (value, maxVal, minVal, precisionVal) => {
  if (toString(value).length == 0) {
    return true;
  }
  const precision = parseInt(precisionVal);
  const min = Decimal(parseFloat(minVal).toFixed(precision));
  const max = Decimal(parseFloat(maxVal).toFixed(precision));

  try {
    let val = Decimal(value);
    if (val.lessThan(min)) {
      return false;
    }

    if (val.greaterThan(max)) {
      return false;
    }

    if (value.indexOf(".") !== -1) {
      if (value.split(".").pop().length !== precision) {
        return false;
      }
      else {
        return true;
      }
    }
    return true;
  }
  catch (e) {
    return false;
  }

}

const sortRoleTypes = (roleTypes) => {
  /**
   * 1 - OM, 2 - UM, 3 - R, 7 - AR, 4 - DE, 6 - U, 8 - SF
   */
  const order = {
    // role id : sort position
    7: 4,
    4: 5,
    5: 7
  };
  const sorter = (a,b) => {
    return ( order.hasOwnProperty(a['id']) ? order[a['id']] : a['id'] ) - ( order.hasOwnProperty(b['id']) ? order[b['id']] : b['id'] )
  };
  return roleTypes.sort(sorter);
}

const validDateRange = (start_date, end_date) => {
  let isValid = true;
  const formats = ["DD/MM/YYYY", "D/M/YYYY", "MM/YYYY", "M/YYYY"];
  for (let format of formats) {
    const start = moment(start_date, format);
    const end = moment(end_date, format);
    if (start.isValid() && end.isValid()) {
      isValid = start.isSameOrBefore(end, "day");
      break;
    }
  }
  return isValid;
};

const getFinancialQuarter = date => {
  const month = moment(date).month(); // 0 to 11
  return 1 + ((2 + Math.floor(month / 3)) % 4);
};

const getEndOfMonth = (year, month) => {
  return moment([year, month - 1]).endOf("month"); // month are 0 based
};

const getEndOfQuarter = (year, quarter) => {
  return moment([year, (5 + 3 * quarter) % 12]).endOf("month"); // 5 as months are 0 based
};

const getCurrentFinancialQuarter = () => {
  return getFinancialQuarter(moment.utc().local());
};

const formatDate = date => {
  if(date) {
    return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(
      date.getDate()
    )}`;
  } else {
    return undefined;
  }
};

export {
  alphaSort,
  organisationTypeSort,
  getSafeValue,
  uniq,
  titleCase,
  entityKeyValid,
  entityKeyExists,
  encodeRouteParam,
  decodeRouteParam,
  prepareFilter,
  parseDate,
  validateAbn,
  validateDecimal,
  validatePostcode,
  validateMobile,
  validateEmail,
  sortRoleTypes,
  validDateRange,
  getFinancialQuarter,
  getEndOfMonth,
  getEndOfQuarter,
  getCurrentFinancialQuarter,
  formatDate,
};
