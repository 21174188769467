import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useErrorTrackingStore = defineStore('errorTracking', () => {
  const userLoaded = ref(false);

  const config = {
    accessToken: "0d714c9a71634b1c924b58ce71fc8317",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        source_map_enabled: true,
        code_version: window.strategic_data_config.GIT_SHA,
      },
      server: {
        host: window.strategic_data_config.streuthSite
      },
      environment: window.strategic_data_config.deployment,
    },
  };

  function throwError(msg, content) {
    if (window.Rollbar) {
      window.Rollbar.error(msg, content);
    }
  }

  function addUserConfig(user) {
    config['payload']['person'] = user;

    if (userLoaded.value) return;

    if (window.Rollbar) {
      window.Rollbar.configure(config);
      userLoaded.value = true;
    }
  }

  function removePayloadUser() {
    delete config.payload.person;
    userLoaded.value = false;
  }

  return {
    // state
    config,

    // getter
    // none

    // actions
    throwError,
    addUserConfig,
    removePayloadUser,
  };
});
