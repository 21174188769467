import { defineStore } from 'pinia';
import axios from 'axios';
import api from '@/service/api';
import router from '@/router';
import {
  has,
  isEmpty,
  isString,
  isUndefined,
  forEach,
  toString,
} from 'lodash';
import { ref, computed } from 'vue';
import { useNotificationStore } from '@/store/notification';
import { usePersistenceStore } from '@/store/persistence';
import { useUploadNotificationStore } from '@/store/uploadNotification';
import { useUnsavedAlertStore } from '@/store/unsavedAlert';
import { useMetaStore } from '@/store/meta';
import { useErrorTrackingStore } from '@/store/errorTracking';

import MATRIX from "./matrix.json";

export const useUserStore = defineStore('user', () => {
  const user = ref({});

  const loggedIn = computed(() => has(user.value, 'sessionId'));
  const areOrgsLoading = ref(false);
  const userOrganisations = computed(() => user.value['organisations']);

  // noConfigApi = axios without the error interceptor config and notification alert
  const noConfigApi = axios.create({ baseURL: '/api' });
  const persistence = usePersistenceStore();

   // Check if a user has 'admin' flag set in Streuth.
  function isAdmin() {
    if (!isEmpty(user.value) && user.value.attributes) {
      return user.value.attributes.admin === 1;
    }
    return false;
  }

   // Check if a user has 'doh' (Department of Health) flag set in Streuth.
  function isDoh() {
    if (!isEmpty(user.value) && user.value.attributes) {
      return user.value.attributes.doh === 1;
    }
    return false;
  }

 // Check if a user has 'state_org' (State/Territory) flag set in Streuth.
  function isStateOrg() {
    if (!isEmpty(user.value) && user.value.attributes) {
      return user.value.attributes.state_org === 1;
    }
    return false;
  }

  function userType() {
    if (isDoh()) return 'doh';
    else if (isStateOrg()) return 'stateOrg';
    else return 'user';
  }

  function getProviderOrganisations(custodian_organisation_path) {
    return user.value.organisations.filter(
      org => org.custodian_organisation_path === custodian_organisation_path
    );
  }

  /**
   * Returns a count of how many provider organisations the user is part of.
   * @returns {Integer} A count of provider organisations
   */
  function getProviderOrganisationsCount(role = undefined) {
    //provider orgs
    let orgs = user.value.organisations.filter(
      _ => _.custodian_organisation_path !== undefined
    );

    if (!role) return orgs.length;

    // Else we need to get organisations through a role filter.
    orgs = getOrganisationsForRole(role);
    return orgs.filter(_ => _.custodian_organisation_path !== undefined).length;
  }

  /**
   * details the current users organisations for which they have a particular role.
   * @param {String} roleName The name of the role to check against.
   * @returns {Array} An array of organisations that the current user has the specified role in.
   */

  function getOrganisationsForRole(roleName, roleLevel = "any") {
    if (isAdmin()) {
      return user.value.organisations;
    }
    return user.value.organisations.filter(org => {
      return hasRoleForOrganisation(
        org.organisation_path,
        roleName,
        roleLevel
      );
    });
  }

  /**
   * Search through the current user's roles to see if they have a role for a given organisation.
   * Passes through [isAdmin]{@link Auth#isAdmin}.
   *
   * @param {!String} orgPath The organisation name to search for.
   * @param {!String} roleName The role name to search for.
   * @param {String} [roleType=any] The role type to search for.
   * @returns {Boolean} Whether the current user has the role or not.
   * @example
   * //returns true if the user has inherited User Management on orga.
   * Auth.hasRoleForOrganisation('orga', 'User Management', 'inherited');
   * @example
   * //returns true if the user has inherited or assigned User Management on orga.
   * Auth.hasRoleForOrganisation('orga', 'User Management');
   */
  function hasRoleForOrganisation(orgPath, roleName, roleType = "any") {
    // update to be array
    if (isString(roleName)) {
      roleName = [roleName,];
    }

    if (isAdmin()) {
      return true;
    }

    const roles = user.value.roles[orgPath];
    if (isUndefined(roles)) {
      return false;
    }

    let rolesToSearch = [];

    if (roleType === "any") {
      // We need to search both inherited AND assigned.
      rolesToSearch = roles.assigned.concat(roles.inherited);
    }
    else {
      rolesToSearch = roles[roleType];
    }

    let found = false;

    forEach(roleName, e => {
      if (
        roles &&
        rolesToSearch.length > 0 &&
        rolesToSearch.map(role => role.name).includes(e)
      ) {
        found = true;
      }
    });

    return found;
  }

  /**
   * details an organisation object by path
   * @param {String} orgPath The name of the organisation to find.
   * @returns {Object} An object representing the organisation to search for
   */
  function getOrganisationByPath(orgPath) {
    return user.value.organisations.find(org => {
      return org.organisation_path === orgPath;
    });
  }

  function getOrganisationParentByPath(orgPath) {
    user.value.organisations.find(org => {
      if (org.organisation_path === orgPath) {
        return user.value.organisations.find(_ => {
          return _.organisation_path === org.custodian_organisation_path;
        });
      }
    });
  }

  function hasAccessToRoute(routeName) {
    return user.value.routeArray.indexOf(routeName) !== -1;
  }

  async function fetch(useToken=false) {
    try {
      const params = {};
      if (useToken) {
        let matches = /login_token=(.*)$/.exec(window.location.hash);
        params.login_token = matches[1];
      }
      const response = await noConfigApi.get('current-user', { params });

      user.value = {...user.value, ...response.data};
      useErrorTrackingStore().addUserConfig(response.data);

      user.value.routeArray = [];
      const type = userType();
      for (let role of user.value.roles) {
        if ( typeof MATRIX[role][type] !== "undefined" ) {
          user.value.routeArray = user.value.routeArray.concat(MATRIX[role][type]);
        }
      }
      user.value.routeArray = [...new Set(user.value.routeArray)];

      persistence.setPhrase(user.value.uuid);
    }
    catch (error) {
      reset();
    }
  }

  async function logout(timeout = false) {
    const response = await noConfigApi.get('logout');
    reset();
    useNotificationStore().clear();
    useUploadNotificationStore().clear();
    persistence.clearAll();
    useMetaStore().reset();
    useErrorTrackingStore().removePayloadUser();
    const dest = timeout ? { name: 'states.login', query: { timeout: true } } : { name: 'home' };
    await router.push(dest);
    // say yes to pass through onBeforeRouteLeave component hook
    await useUnsavedAlertStore().setAnswer(true);
    // then reset the store
    useUnsavedAlertStore().reset();
  }

  async function getOrganisations(force=false) {
    // currently loading, no need to do this again
    if (!force && areOrgsLoading.value) return;

    // loaded, no need to do it again
    if (user.value['organisations']) {
      areOrgsLoading.value = false;
      return;
    }

    areOrgsLoading.value = true;
    let url = isAdmin() ? '/organisations' : `/users/${ user.value.uuid }/organisations`;
    const response = await api.get(url);
    user.value['organisations'] = response.data;
    areOrgsLoading.value = false;
  }

  function reset() {
    user.value = { };
  }

  return {
    // state
    user,
    areOrgsLoading,

    // getter
    loggedIn,
    userOrganisations,

    // actions
    isAdmin,
    isDoh,
    isStateOrg,
    userType,
    fetch,
    logout,
    getOrganisations,
    hasRoleForOrganisation,
    getOrganisationsForRole,
    getProviderOrganisations,
    getProviderOrganisationsCount,
    getOrganisationByPath,
    getOrganisationParentByPath,
    hasAccessToRoute,
  }
})
