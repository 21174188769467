<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { useRoute } from 'vue-router';

const Layouts = {
  DefaultLayout: defineAsyncComponent(() => import('@/layouts/DefaultLayout.vue')),
};

const route  = useRoute();
const layout = computed(() => Layouts[route.meta.layout || 'LoadingLayout']);

</script>
