import { defineStore } from 'pinia';
import { ref, computed } from 'vue';


// let cryptoJS = require("crypto-js");

// // Encrypt
// let ciphertext = cryptoJS.AES.encrypt('my message', 'secret key 123').toString();

// // Decrypt
// let bytes  = cryptoJS.AES.decrypt(ciphertext, 'secret key 123');
// let originalText = bytes.toString(cryptoJS.enc.Utf8);

// console.log(originalText); // 'my message'

import CryptoJS from "crypto-js";

const REFLECTION_STORAGE = "persistence";
export const usePersistenceStore = defineStore('persistence', () => {
  const cryptoJS = CryptoJS;
  let localStorageAvailable = ref(false);
  let currentStorage = ref(get(REFLECTION_STORAGE) || []);
  let phrase = ref(undefined);
  try {
    window.localStorage.setItem("testKey", "test");
    window.localStorage.removeItem("testKey");
    localStorageAvailable.value = true;
  }
  catch (e) {
    // do nothing
  }

  function setPhrase(entry) {
    // This gets called when the auth service is initiated. 
    // Which means that it will always be set when we call set/get in
    // this service.
    phrase.value = cryptoJS.SHA1(entry).toString();
  }


  function set(key, obj = undefined, dates = []) {

    if (!localStorageAvailable.value) return;
    if (dates.length !== 0 && obj !== undefined) {
      obj.$$dates = dates;
    }
    // check this, otherwise cryptoJs throws error
    if(phrase.value) {
      let data = cryptoJS.AES.encrypt(JSON.stringify(obj), phrase.value);
  
      window.localStorage.setItem(
        key,
        data
      );
  
      if (currentStorage.value.indexOf(key) === -1) {
        currentStorage.value.push(key);
        set(REFLECTION_STORAGE, currentStorage.value);
      }
    }
  }

  function get(key) {
    if (!localStorageAvailable.value) return undefined;
    let obj = window.localStorage.getItem(key);
    if (!obj) {
      return undefined;
    }

    try {
      let bytes = cryptoJS.AES.decrypt(obj, phrase.value);
      obj = JSON.parse(bytes.toString(cryptoJS.enc.Utf8));
    }
    catch (err) {
      if (err.message === 'Malformed UTF-8 data') {
        // if CryptoJs can not parse the object, clear it
        // (it will be helpful for clientSearchData which used to store in plain object in previous versions)
        clear(key);
        obj = undefined;
      }
    }
    finally {
      if (obj) {
        if (obj.$$dates) {
          for (let date of obj.$$dates) {
            obj[date] = new Date(obj[date]);
          }
        }
      }
      return obj;
    }
  }

  function clear(key, all = false) {
    if (!localStorageAvailable.value) return;
    if (!all) {
      window.localStorage.removeItem(key);
      currentStorage.value = currentStorage.value.filter(_ => _ !== key);
      set(REFLECTION_STORAGE, currentStorage.value);
    }
    else {
      for (let k of currentStorage.value) {
        window.localStorage.removeItem(k);
      }
    }
  }

  function clearAll() {
    if (!localStorageAvailable.value) return;
    phrase.value = undefined;
    try {
      window.localStorage.clear();
      currentStorage.value = [];
    }
    catch(err) {
      console.log('Storage is not available.');
    }
  }

  return {
    setPhrase,
    set,
    get,
    clear,
    clearAll,
  }
});


