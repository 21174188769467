import {
  createApp,
  provide,
} from 'vue';

import App from './App.vue'

import './assets/bootstrap.scss'
const app = createApp(App);

import { createPinia } from 'pinia';
const pinia = createPinia();
app.use(pinia);

import Router from './router.js';
app.use(Router);

import trackingPlugin from './plugins/tracking';
import { useErrorTrackingStore } from '@/store/errorTracking';
const trackingStore = useErrorTrackingStore();
app.use(trackingPlugin, trackingStore.config);

app.config.errorHandler = (err, instance, info) => {
  // https://vuejs.org/api/application.html#app-config-errorhandler
  if (window.Rollbar) {
    window.Rollbar.error(err);
  }
  throw err; // rethrow
}

import { useNotificationStore } from '@/store/notification';
import Emitter from '@/service/emitter';
const globalEmitter = new Emitter();

globalEmitter.on('submitForm', async function() {
  // tasks we need to do before proceeding to submit the form data
  useNotificationStore().clear();
  // short interuption to let TagsInput out-focus autofill function finish running
  await new Promise(resolve => setTimeout(resolve, 200));
});
app.provide('globalEmitter', globalEmitter);


app.mount('#app');
