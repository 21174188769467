import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';
import router from '@/router';

export const useUnsavedAlertStore = defineStore('unsavedAlert', () => {

  const alertOpen = ref(false);
  const answer = ref(false);
  const destination = ref({});
  const model = ref({});

  function setAlert(val) {
    alertOpen.value = val;
  }

  async function setAnswer(val) {
    answer.value = val;
    if (val) {
      setAlert(false);
      // use router.push to re-trigger onBeforeRouteLeave guards with all updated value
      // and let us proceed to the destination
      await router.push({...destination.value});
    }
  }

  function setDestination(dest) {
    destination.value = cloneDeep(dest);
  }

  function setModel(content) {
    model.value = cloneDeep(content);
  }

  function reset() {
    alertOpen.value = false;
    answer.value = false;
    destination.value = { };
    model.value = { };
  }

  return {
    // state
    alertOpen,
    answer,
    destination,
    model,

    // getters

    // actions
    setAlert, setAnswer, reset, setDestination, setModel,
  };
});
