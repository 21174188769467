import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

export const useNotificationStore = defineStore('notification', () => {

  const notifications = ref([]);

  function addNotification(notification) {
    notifications.value.push(notification);
  }

  function addSuccess(message, tabPersistList = [], link = {}) {
    addNotification({ message, link, tabPersistList, type: 'success' });
  }

  function addError(message, tabPersistList = [], link = {}) {
    addNotification({ message, link, tabPersistList, type: 'danger' });
  }

  function dismiss(index) {
    notifications.value.splice(index, 1);
  }

  function clear(targetState = undefined) {
    if (targetState === undefined) {
      notifications.value = [];
      return;
    }

    const removes = [];
    for (let entry of notifications.value) {
      if (entry.tabPersistList.indexOf(targetState) > -1 || entry.tabPersistList === "*") {
        continue;
      }
      removes.push(notifications.value.indexOf(entry));
    }
    for (let idx of removes) {
      dismiss(idx);
    }
  }

  return {
    // state
    notifications,

    // getters
    // none

    // actions
    addSuccess, addError, dismiss, clear,
  };
});
