import { defineStore } from 'pinia';
import { cloneDeep, toString } from 'lodash';
import { ref } from 'vue';
import api from '@/service/api';

export const useMetaStore = defineStore('meta', () => {
  const metaContent = ref({});

  function set(url, data) {
    metaContent.value[url] = cloneDeep(data);
  }

  function getRaw(url) {
    return metaContent.value[url];
  }

  function getPromise(url) {
    // wrap returned meta data content in a promise
    const data = metaContent.value[url];
    if (toString(data).length) {
      // reused
      return new Promise((resolve, reject) => {
        resolve({ data });
      });
    }
    // brand new
    return api.get(url);
  }

  function reset() {
    metaContent.value = {};
  }

  return {
    // state
    metaContent,

    // getter
    // none

    // actions
    set, getRaw, getPromise, reset,
  };
});
