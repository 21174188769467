import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { isUndefined, toString, cloneDeep } from 'lodash';
import { useNotificationStore } from '@/store/notification';
import api from '@/service/api';
import router from '@/router';

export const useUploadNotificationStore = defineStore('uploadNotification', () => {

  const notificationStore = useNotificationStore();

  const trackingUploadIds = ref({});
  const interval = 2 * 1000; // every 2 seconds
  let intervalId = null;

  function addTracking(uuid, uploadData) {
    trackingUploadIds.value[uuid] = cloneDeep(uploadData);
    if (!intervalId) { // start working
      intervalId = setInterval(trackAllUploadIds, interval);
    }
  }

  async function trackAllUploadIds() {
    for (let item of Object.keys(trackingUploadIds.value)) {
      await getUploadStatus(item);
    }
  }

  async function getUploadStatus(uuid) {
    const currentUploadData = cloneDeep(trackingUploadIds.value[uuid]);
    const url = `/organisations/${ currentUploadData.organisation.encoded_organisation_path }/uploads/${ uuid }`;
    const r = await api.get(url);
    if (r.data.status !== currentUploadData.status || ["error", "complete"].includes(r.data.status)) {
      const link = {
        text: "Click here to see more details.",
        url: router.resolve({
          name: 'upload.details.full_details',
          params: { uuid, encoded_organisation_path: currentUploadData.organisation.encoded_organisation_path },
        }).href,
      }
      if (r.data.status === 'error') {
        const msg = `There has been an error with your upload of ${r.data.filename} to ${r.data.organisation.organisation_name}.`;
        notificationStore.addError(msg, '*', link);
        delete trackingUploadIds.value[uuid];
      }
      else if (r.data.status === 'complete') {
        const msg = `${r.data.filename} has successfully been uploaded to ${r.data.organisation.organisation_name}.`;
        notificationStore.addSuccess(msg, '*', link);
        delete trackingUploadIds.value[uuid];
      }
      else {
        trackingUploadIds.value[uuid] = r.data;
      }
    }
  }

  function clear() {
    trackingUploadIds.value = {};
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  }

  return {
    // state
    trackingUploadIds,

    // getters
    // none

    // actions
    addTracking,
    clear,
  };
});
