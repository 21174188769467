import axios from 'axios';
import { get } from 'lodash';

import router from '@/router';
import {titleCase,} from '@/helpers';

import { useNotificationStore } from '@/store/notification';
import { useMetaStore } from '@/store/meta';
import { useUserStore } from '@/store/user';
import { useErrorTrackingStore } from '@/store/errorTracking';

const http = axios.create({
  baseURL: '/api',
  paramsSerializer: {
    indexes: null, // array indexes format: no brackets
  },
  responseType: 'json',
});

http.interceptors.request.use(
  function (config) {
    const userStore = useUserStore();
    if (userStore.loggedIn) {
      const sessionId = userStore.user.sessionId;
      config.headers.Authorization = "Bearer " + sessionId;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  function (response) {
    const url = response.config.url;
    if (url.includes('meta')) {
      useMetaStore().set(response.config.url, response.data);
    }
    return response;
  },
  function (error) {
    //console.log(error.response);
    const store = useNotificationStore();
    let rejection = get(error, 'response');

    if (!rejection || !rejection.status) {
       return Promise.reject(error);
    }

    if (rejection.status === -1) {
      useErrorTrackingStore().throwError("Http Error Interceptor", rejection);
    }

    if (rejection.status === 403 && rejection.data) {
      // no access, redirect to home
      router.push({ name: 'home' });
      store.addError(
        "You are not currently signed in, or authorised to access some features of the PMHC MDS."
      );
    }
    else {
      // render the error data
      if (rejection.data.errors) {
        for (const item in rejection.data.errors) {
          const body_param_match = item.match('^body\.(.+)$');
          const body_param = ( body_param_match && typeof(body_param_match[1]) !== 'undefined' )
                             ? body_param_match[1] + " " : '';
          const text = "An error occurred: "
                       + titleCase(body_param)
                       + rejection.data.errors[item];
          store.addError(text);
        }
      }
      else {
        const text =
          rejection.status +
          " " +
          rejection.statusText +
          ": " +
          rejection.data.exception +
          ": " +
          rejection.data.message;
        store.addError(text);
      }
    }
    return Promise.reject(error);
  }
);
export default http;
