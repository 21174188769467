import { createRouter, createWebHashHistory } from 'vue-router';

import { cloneDeep, forEach, isEmpty, toString } from 'lodash';

import api from '@/service/api';

// Any paths we define here must have matching location entries in nginx.
// See: ui/image/etc/nginx/conf.d/default.conf
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      noAuthRequired: true,
    },
  },
  {
    path: '/login',
    name: 'states.login',
    component: () => import('@/views/Login.vue'),
    meta: {
      noAuthRequired: true,
      breadcrumb: 'Login',
    },
  },
  {
    path: '/403',
    name: 'states.errors.403',
    component: () => import('@/views/403.vue'),
  },
  // organisations routes
  {
    path: '/organisations',
    name: 'organisations',
    meta: {
      breadcrumbAlias: 'organisations.table',
    },
    redirect: { name: 'organisations.table' },
    component: () => import('@/views/Organisations.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Organisations',
        },
        path: 'list',
        name: 'organisations.table',
        component: () => import('@/components/organisation/commissionOrg/CommissionOrgTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Organisations',
        },
        path: 'add',
        name: 'organisations.add',
        component: () => import('@/components/organisation/commissionOrg/AddOrgForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'View Provider Organisations'
        },
        path: 'provider_organisations/list',
        name: 'organisations.provider_organisations.table',
        component: () => import('@/components/organisation/providerOrg/ProviderOrgTable.vue'),
      },
    ],
  },
  {
    path: '/organisations/:encoded_organisation_path',
    name: 'organisations.details',
    meta: {
      breadcrumbAlias: 'organisations.details.full_details',
      breadcrumbVariable: 'encoded_organisation_path',
    },
    redirect: { name: 'organisations.details.full_details' },
    component: () => import('@/views/OrganisationsDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: 'details',
        name: 'organisations.details.full_details',
        component: () => import('@/components/organisation/commissionOrg/DetailsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'organisations.details.edit',
        component: () => import('@/components/organisation/commissionOrg/EditOrgForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'View Provider Organisations',
        },
        path: 'provider_organisations/list',
        name: 'organisations.details.provider_organisations.table',
        component: () => import('@/components/organisation/commissionOrg/LinkedProviderOrgTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Add Provider Organisation',
        },
        path: 'provider_organisations/add',
        name: 'organisations.details.provider_organisations.add',
        component: () => import('@/components/organisation/providerOrg/AddProviderOrgForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'View Users',
        },
        path: 'users',
        name: 'organisations.details.users',
        component: () => import('@/components/organisation/commissionOrg/UserTable.vue'),
      },
    ],
  },
  {
    path: '/organisations/:encoded_organisation_path/provider_organisations/:encoded_provider_organisation_path',
    name: 'organisations.details.provider_organisations.details',
    meta: {
      breadcrumbAlias: 'organisations.details.provider_organisations.details.full_details',
      breadcrumbVariable: 'encoded_provider_organisation_path',
    },
    redirect: { name: 'organisations.details.provider_organisations.details.full_details' },
    component: () => import('@/views/ProviderOrganisationsDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: 'details',
        name: 'organisations.details.provider_organisations.details.full_details',
        component: () => import('@/components/organisation/providerOrg/DetailsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'organisations.details.provider_organisations.details.edit',
        component: () => import('@/components/organisation/providerOrg/EditProviderOrgForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'View Users',
        },
        path: 'users',
        name: 'organisations.details.provider_organisations.details.users',
        component: () => import('@/components/organisation/commissionOrg/UserTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'View Sites',
        },
        path: 'sites/list',
        name: 'organisations.details.provider_organisations.details.sites.table',
        component: () => import('@/components/organisation/providerOrg/site/SiteTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Add New Site',
        },
        path: 'sites/add',
        name: 'organisations.details.provider_organisations.details.sites.add',
        component: () => import('@/components/organisation/providerOrg/site/AddSiteForm.vue'),
      },
    ],
  },

  {
    path: '/organisations/:encoded_organisation_path/provider_organisations/:encoded_provider_organisation_path/sites/:encoded_site_key',
    name: 'organisations.details.provider_organisations.details.sites.details',
    meta: {
      breadcrumbAlias: 'organisations.details.provider_organisations.details.sites.details.full_details',
      breadcrumbVariable: 'encoded_site_key',
    },
    redirect: { name: 'organisations.details.provider_organisations.details.sites.details.full_details' },
    component: () => import('@/views/SiteDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: 'details',
        name: 'organisations.details.provider_organisations.details.sites.details.full_details',
        component: () => import('@/components/organisation/providerOrg/site/SiteDetailsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'organisations.details.provider_organisations.details.sites.details.edit',
        component: () => import('@/components/organisation/providerOrg/site/EditSiteForm.vue'),
      },
    ],
  },

  // data entry routes
  {
    path: '/data-entry',
    name: 'data-entry',
    meta: {
      breadcrumb: 'Data Entry',
    },
    redirect: { name: 'data-entry.clients.table' },
    component: () => import('@/views/DataEntry.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Clients',
        },
        path: 'clients',
        name: 'data-entry.clients',
        redirect: { name: 'data-entry.clients.table' },
        component: { template: `<p>placeholder, not using</p>` },
      },
      {
        meta: {
          breadcrumb: 'View Clients',
        },
        path: 'clients/list',
        name: 'data-entry.clients.table',
        component: () => import('@/components/data-entry/client/ClientTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Add New Client',
        },
        path: 'clients/add',
        name: 'data-entry.clients.add',
        component: () => import('@/components/data-entry/client/AddClientForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Practitioners',
        },
        path: 'practitioners',
        name: 'data-entry.practitioners',
        redirect: { name: 'data-entry.practitioners.table' },
        component: { template: `<p>placeholder, not using</p>` },
      },
      {
        meta: {
          breadcrumb: 'View Practitioners',
        },
        path: 'practitioners/list',
        name: 'data-entry.practitioners.table',
        component: () => import('@/components/data-entry/practitioner/PractitionerTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Add New Practitioner',
        },
        path: 'practitioners/add',
        name: 'data-entry.practitioners.add',
        component: () => import('@/components/data-entry/practitioner/AddPractitionerForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Activities',
        },
        path: 'activities',
        name: 'data-entry.activities',
        redirect: { name: 'data-entry.activities.table' },
        component: { template: `<p>placeholder, not using</p>` },
      },
      {
        meta: {
          breadcrumb: 'View Activities',
        },
        path: 'activities/list',
        name: 'data-entry.activities.table',
        component: () => import('@/components/data-entry/activity/ActivityTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Add New Activity',
        },
        path: 'activities/add',
        name: 'data-entry.activities.add',
        component: () => import('@/components/data-entry/activity/AddActivityForm.vue'),
      },
    ],
  },
  {
    path: '/data-entry/practitioners/:encoded_organisation_path/:encoded_practitioner_key',
    name: 'data-entry.practitioners.details',
    meta: {
      breadcrumbAlias: 'data-entry.practitioners.details.full_details',
      breadcrumbVariable: 'encoded_practitioner_key@encoded_organisation_path',
    },
    redirect: { name: 'data-entry.practitioners.details.full_details' },
    component: () => import('@/views/PractitionerDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: 'details',
        name: 'data-entry.practitioners.details.full_details',
        component: () => import('@/components/data-entry/practitioner/DetailsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'data-entry.practitioners.details.edit',
        component: () => import('@/components/data-entry/practitioner/EditPractitionerForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'View Clients',
        },
        path: 'clients',
        name: 'data-entry.practitioners.details.clients',
        component: () => import('@/components/data-entry/practitioner/LinkedClientTable.vue'),
      },
    ],
  },
  {
    path: '/data-entry/activities/:encoded_organisation_path/:encoded_nspt_activity_key',
    name: 'data-entry.activities.details',
    meta: {
      breadcrumbAlias: 'data-entry.activities.details.full_details',
      breadcrumbVariable: 'encoded_nspt_activity_key',
    },
    redirect: { name: 'data-entry.activities.details.full_details' },
    component: () => import('@/views/ActivityDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: 'details',
        name: 'data-entry.activities.details.full_details',
        component: () => import('@/components/data-entry/activity/DetailsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'data-entry.activities.details.edit',
        component: () => import('@/components/data-entry/activity/EditActivityForm.vue'),
      },
    ],
  },
  {
    path: '/data-entry/clients/:encoded_client_key@:encoded_organisation_path',
    name: 'data-entry.clients.details',
    meta: {
      breadcrumbAlias: 'data-entry.clients.details.summary',
      breadcrumbVariable: 'encoded_client_key@encoded_organisation_path',
    },
    redirect: { name: 'data-entry.clients.details.summary' },
    component: () => import('@/views/ClientDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Summary',
        },
        path: 'summary',
        name: 'data-entry.clients.details.summary',
        component: () => import('@/components/data-entry/client/ClientSummary.vue'),
      },
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: 'details',
        name: 'data-entry.clients.details.full_details',
        component: () => import('@/components/data-entry/client/DetailsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'data-entry.clients.details.edit',
        component: () => import('@/components/data-entry/client/EditClientForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Client Intakes',
        },
        path: 'intakes',
        name: 'data-entry.clients.details.intakes',
        redirect: { name: 'data-entry.clients.details.intakes.table' },
        component: { template: `<p>placeholder, not using</p>` },
      },
      {
        meta: {
          breadcrumb: 'View Intakes',
        },
        path: 'intakes/list',
        name: 'data-entry.clients.details.intakes.table',
        component: () => import('@/components/data-entry/intake/IntakeTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Add New Intake',
        },
        path: 'intakes/add',
        name: 'data-entry.clients.details.intakes.add',
        component: () => import('@/components/data-entry/intake/AddIntakeForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Client Episodes',
        },
        path: 'episodes',
        name: 'data-entry.clients.details.episodes',
        redirect: { name: 'data-entry.clients.details.episodes.table' },
        component: { template: `<p>placeholder, not using</p>` },
      },
      {
        meta: {
          breadcrumb: 'View Episodes',
        },
        path: 'episodes/list',
        name: 'data-entry.clients.details.episodes.table',
        component: () => import('@/components/data-entry/episode/EpisodeTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Add New Episode',
        },
        path: 'episodes/add',
        name: 'data-entry.clients.details.episodes.add',
        component: () => import('@/components/data-entry/episode/AddEpisodeForm.vue'),
      },
    ],
  },
  {
    path: '/data-entry/clients/:encoded_client_key@:encoded_organisation_path/intakes/:encoded_intake_key',
    name: 'data-entry.clients.details.intakes.details',
    redirect: { name: 'data-entry.clients.details.intakes.details.full_details' },
    component: () => import('@/views/IntakeDetails.vue'),
    children: [
      {
        path: 'details',
        name: 'data-entry.clients.details.intakes.details.full_details',
        redirect: { name: 'data-entry.clients.details.intakes.details.full_details.iar-dst.table' },
        component: () => import('@/components/data-entry/intake/DetailsForm.vue'),
        children: [
          {
            meta: {
              breadcrumbAlias: 'data-entry.clients.details.intakes.details.full_details',
              breadcrumbVariable: 'encoded_intake_key',
            },
            redirect: { name: 'data-entry.clients.details.intakes.details.full_details.iar-dst.table' },
            name: 'data-entry.clients.details.intakes.details.full_details.iar-dst',
            path: 'iar-dst',
            component: { template: `<p>placeholder, not using</p>` },
          },
          {
            meta: {
              breadcrumb: 'View Details',
            },
            name: 'data-entry.clients.details.intakes.details.full_details.iar-dst.table',
            path: 'iar-dst/list',
            component: () => import('@/components/data-entry/intake/iar-dst/IarDstTable.vue'),
          },
          {
            name: 'data-entry.clients.details.intakes.details.full_details.iar-dst.add',
            path: 'iar-dst/add',
            component: () => import('@/components/data-entry/intake/iar-dst/AddIarDstForm.vue'),
          },
          {
            name: 'data-entry.clients.details.intakes.details.full_details.iar-dst.details',
            path: 'iar-dst/:encoded_measure_key',
            redirect: { name: 'data-entry.clients.details.intakes.details.full_details.iar-dst.details.full_details' },
            component: { template: `<p>placeholder, not using</p>` },
          },
          {
            name: 'data-entry.clients.details.intakes.details.full_details.iar-dst.details.full_details',
            path: 'iar-dst/:encoded_measure_key/details',
            component: () => import('@/components/data-entry/intake/iar-dst/DetailsForm.vue'),
          },
          {
            name: 'data-entry.clients.details.intakes.details.full_details.iar-dst.details.edit',
            path: 'iar-dst/:encoded_measure_key/edit',
            component: () => import('@/components/data-entry/intake/iar-dst/EditIarDstForm.vue'),
          },
        ],
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'data-entry.clients.details.intakes.details.edit',
        component: () => import('@/components/data-entry/intake/EditIntakeForm.vue'),
      },
    ],
  },
  {
    path: '/data-entry/clients/:encoded_client_key@:encoded_organisation_path/episodes/:encoded_episode_key',
    name: 'data-entry.clients.details.episodes.details',
    meta: {
      breadcrumbAlias: 'data-entry.clients.details.episodes.details.full_details',
      breadcrumbVariable: 'encoded_episode_key',
    },
    redirect: { name: 'data-entry.clients.details.episodes.details.full_details' },
    component: () => import('@/views/EpisodeDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: 'details',
        name: 'data-entry.clients.details.episodes.details.full_details',
        component: () => import('@/components/data-entry/episode/DetailsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'data-entry.clients.details.episodes.details.edit',
        component: () => import('@/components/data-entry/episode/EditEpisodeForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Collection Occasions',
        },
        path: 'collection-occasions',
        name: 'data-entry.clients.details.episodes.details.collection-occasions',
        component: { template: `<p>placeholder, not using</p>` },
        redirect: { name: 'data-entry.clients.details.episodes.details.collection-occasions.table' },
      },
      {
        meta: {
          breadcrumb: 'View Collection Occasions',
        },
        path: 'collection-occasions/list',
        name: 'data-entry.clients.details.episodes.details.collection-occasions.table',
        component: () => import('@/components/data-entry/collection-occasion/COsTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Add New Collection Occasion',
        },
        path: 'collection-occasions/add',
        name: 'data-entry.clients.details.episodes.details.collection-occasions.add',
        component: () => import('@/components/data-entry/collection-occasion/AddCOsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Generate Self Service Link',
        },
        path: 'collection-occasions/self-service',
        name: 'data-entry.clients.details.episodes.details.collection-occasions.self-service',
        component: () => import('@/components/data-entry/self-service/GenerateLinkForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Service Contacts',
        },
        path: 'service-contacts',
        name: 'data-entry.clients.details.episodes.details.service-contacts',
        component: { template: `<p>placeholder, not using</p>` },
        redirect: { name: 'data-entry.clients.details.episodes.details.service-contacts.table' },
      },
      {
        meta: {
          breadcrumb: 'View Service Contacts',
        },
        path: 'service-contacts/list',
        name: 'data-entry.clients.details.episodes.details.service-contacts.table',
        component: () => import('@/components/data-entry/service-contact/ServiceContactTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Add New Service Contact',
        },
        path: 'service-contacts/add',
        name: 'data-entry.clients.details.episodes.details.service-contacts.add',
        component: () => import('@/components/data-entry/service-contact/AddServiceContactForm.vue'),
      },
    ],
  },
  {
    path: '/data-entry/clients/:encoded_client_key@:encoded_organisation_path/episodes/:encoded_episode_key/service-contacts/:encoded_service_contact_key',
    name: 'data-entry.clients.details.episodes.details.service-contacts.details',
    meta: {
      breadcrumbAlias: 'data-entry.clients.details.episodes.details.service-contacts.details',
      breadcrumbVariable: 'encoded_service_contact_key',
    },
    redirect: { name: 'data-entry.clients.details.episodes.details.service-contacts.details.full_details' },
    component: () => import('@/views/ServiceContactDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: 'details',
        name: 'data-entry.clients.details.episodes.details.service-contacts.details.full_details',
        component: () => import('@/components/data-entry/service-contact/DetailsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'data-entry.clients.details.episodes.details.service-contacts.details.edit',
        component: () => import('@/components/data-entry/service-contact/EditServiceContactForm.vue'),
      },
    ],
  },
  {
    path: '/data-entry/clients/:encoded_client_key@:encoded_organisation_path/episodes/:encoded_episode_key/collection-occasions/:encoded_collection_occasion_key',
    name: 'data-entry.clients.details.episodes.details.collection-occasions.details',
    redirect: { name: 'data-entry.clients.details.episodes.details.collection-occasions.details.full_details' },
    component: () => import('@/views/CollectionOccasionDetails.vue'),
    children: [
      {
        path: 'details',
        name: 'data-entry.clients.details.episodes.details.collection-occasions.details.full_details',
        redirect: { name: 'data-entry.clients.details.collection-occasions.details.full_details.measures.table' },
        component: () => import('@/components/data-entry/collection-occasion/DetailsForm.vue'),
        children: [
          {
            meta: {
              breadcrumbAlias: 'data-entry.clients.details.episodes.details.collection-occasions.details',
              breadcrumbVariable: 'encoded_collection_occasion_key',
            },
            redirect: { name: 'data-entry.clients.details.collection-occasions.details.full_details.measures.table' },
            name: 'data-entry.clients.details.episodes.details.collection-occasions.details.full_details.measures',
            path: 'measures',
            component: { template: `<p>placeholder, not using</p>` },
          },
          {
            meta: {
              breadcrumb: 'View Details',
            },
            name: 'data-entry.clients.details.episodes.details.collection-occasions.details.full_details.measures.table',
            path: 'measures/list',
            component: () => import('@/components/data-entry/collection-occasion/measure/MeasureTable.vue'),
          },
          {
            name: 'data-entry.clients.details.episodes.details.collection-occasions.details.full_details.measures.add',
            path: 'measures/add/:measure_type?/:score_type?',
            component: () => import('@/components/data-entry/collection-occasion/measure/AddMeasureForm.vue'),
          },
          {
            name: 'data-entry.clients.details.episodes.details.collection-occasions.details.full_details.measures.details',
            path: 'measures/:measure_type/:score_type/:encoded_measure_key',
            redirect: {
              name: 'data-entry.clients.details.episodes.details.collection-occasions.details.full_details.measures.details.full_details',
            },
            component: { template: `<p>placeholder, not using</p>` },
          },
          {
            name: 'data-entry.clients.details.episodes.details.collection-occasions.details.full_details.measures.details.full_details',
            path: 'measures/:measure_type/:score_type/:encoded_measure_key/details',
            component: () => import('@/components/data-entry/collection-occasion/measure/DetailsForm.vue'),
          },
          {
            name: 'data-entry.clients.details.episodes.details.collection-occasions.details.full_details.measures.details.edit',
            path: 'measures/:measure_type/:score_type/:encoded_measure_key/edit',
            component: () => import('@/components/data-entry/collection-occasion/measure/EditMeasureForm.vue'),
          },
        ],
      },
      {
        meta: {
          breadcrumb: 'Edit Details',
        },
        path: 'edit',
        name: 'data-entry.clients.details.episodes.details.collection-occasions.details.edit',
        component: () => import('@/components/data-entry/collection-occasion/EditCOsForm.vue'),
      },
    ],
  },
  // data extract routes
  {
    path: '/data-extract',
    name: 'data-extract',
    meta: {
      breadcrumb: 'Data Extract',
    },
    redirect: { name: 'data-extract.request' },
    component: () => import('@/views/DataExtract.vue'),
    children: [
      {
        path: 'request',
        name: 'data-extract.request',
        component: () => import('@/components/data-extract/DataExtractForm.vue'),
      },
      {
        path: 'list',
        name: 'data-extract.table',
        component: () => import('@/components/data-extract/DataExtractTable.vue'),
      },
    ],
  },
  // commonwealth extract routes (deprecated - redirect to consented extract routes)
  {
    path: '/commonwealth-extract',
    name: 'commonwealth-extract',
    meta: {
      breadcrumb: 'Consented Extract',
    },
    redirect: { name: 'consented-extract.table' },
    children: [
      {
        path: 'request',
        name: 'commonwealth-extract.request',
        redirect: { name: 'consented-extract.request' },
      },
      {
        path: 'list',
        name: 'commonwealth-extract.table',
        redirect: { name: 'consented-extract.table' },
      },
    ],
  },
  // consented extract routes
  {
    path: '/consented-extract',
    name: 'consented-extract',
    meta: {
      breadcrumb: 'Consented Extract',
    },
    redirect: { name: 'consented-extract.table' },
    component: () => import('@/views/ConsentedExtract.vue'),
    children: [
      {
        path: 'request',
        name: 'consented-extract.request',
        component: () => import('@/components/consented-extract/ConsentedExtractForm.vue'),
      },
      {
        path: 'list',
        name: 'consented-extract.table',
        component: () => import('@/components/consented-extract/ConsentedExtractTable.vue'),
      },
    ],
  },
  // user routes
  {
    path: '/users',
    name: 'users',
    meta: {
      breadcrumb: 'Users',
    },
    redirect: { name: 'users.table' },
    component: () => import('@/views/User.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Users',
        },
        path: 'list',
        name: 'users.table',
        component: () => import('@/components/user/UserTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Invite New User',
        },
        path: 'invite',
        name: 'users.invite',
        component: () => import('@/components/user/InviteForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Invites'
        },
        path: 'invites',
        name: 'users.invites',
        component: () => import('@/components/user/InviteTable.vue'),
      },
    ],
  },
  {
    path: '/users/details/:uuid',
    name: 'users.details',
    meta: {
      breadcrumb: 'User Details',
    },
    redirect: { name: 'users.details.full_details' },
    component: () => import('@/views/UserDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Details',
        },
        path: '',
        name: 'users.details.full_details',
        component: () => import('@/components/user/DetailsForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'Roles',
        },
        path: 'roles',
        name: 'users.details.roles',
        component: () => import('@/components/user/ViewRolesTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Edit Roles',
        },
        path: 'roles/edit',
        name: 'users.details.roles.edit',
        component: () => import('@/components/user/EditRolesTable.vue'),
      },
    ],
  },
  {
    path: '/users/invites/details',
    name: 'users.invites.details',
    meta: {
      breadcrumb: 'Invite Details',
    },
    redirect: { name: 'users.invites.details.full_details' },
    component: () => import('@/views/UserInviteDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: '',
        name: 'users.invites.details.full_details',
        component: () => import('@/components/user/InviteDetails.vue'),
      },
    ],
  },
  // upload routes
  {
    path: '/upload',
    name: 'upload',
    meta: {
      breadcrumb: 'Upload',
    },
    redirect: { name: 'upload.add' },
    component: () => import('@/views/Upload.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Upload A File',
        },
        path: 'add',
        name: 'upload.add',
        component: () => import('@/components/upload/UploadForm.vue'),
      },
      {
        meta: {
          breadcrumb: 'View Uploads',
        },
        path: 'list',
        name: 'upload.table',
        component: () => import('@/components/upload/UploadTable.vue'),
      },
    ],
  },
  {
    path: '/upload/details/:uuid/:encoded_organisation_path',
    name: 'upload.details',
    redirect: { name: 'upload.details.full_details' },
    component: () => import('@/views/UploadDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'View Details',
        },
        path: '',
        name: 'upload.details.full_details',
        component: () => import('@/components/upload/DetailsForm.vue'),
      },
    ],
  },
  // tools routes (slk + user audit + yes phn)
  {
    path: '/tools',
    name: 'tools',
    meta: {
      noAuthRequired: true,
    },
    component: { template: `<p>placeholder, not using</p>` },
    redirect: { name: 'tools.slk' },
  },
  {
    path: '/tools/slk',
    name: 'tools.slk',
    meta: {
      breadcrumb: 'SLK',
      noAuthRequired: true,
    },
    component: () => import('@/views/Slk.vue'),
    children: [
      {
        path: '',
        name: 'tools.slk',
        component: () => import('@/components/tools/SLKForm.vue'),
      },
    ],
  },
  {
    path: '/tools/user-audit',
    name: 'tools.user-audit',
    meta: {
      breadcrumb: 'User Auditing',
    },
    component: () => import('@/views/UserAudit.vue'),
    children: [
      {
        path: '',
        name: 'tools.user-audit',
        component: () => import('@/components/tools/user-audit/UserAuditTable.vue'),
      },
    ],
  },
  {
    path: '/tools/yes-phn',
    name: 'tools.yes-phn',
    meta: {
      breadcrumb: 'YES PHN',
    },
    redirect: { name: 'tools.yes-phn.download' },
    component: () => import('@/views/YesPhn.vue'),
    children: [
      {
        path: 'download',
        name: 'tools.yes-phn.download',
        meta: {
          breadcrumb: 'Download',
        },
        component: () => import('@/components/tools/yes-phn/DownloadForm.vue'),
      },
      {
        path: 'upload',
        name: 'tools.yes-phn.upload',
        meta: {
          breadcrumb: 'Upload',
        },
        redirect: { name: 'tools.yes-phn.upload.add' },
        component: { template: `<p>placeholder, not using</p>` },
      },
      {
        path: 'upload/add',
        name: 'tools.yes-phn.upload.add',
        meta: {
          breadcrumb: 'Make New Upload',
        },
        component: () => import('@/components/tools/yes-phn/UploadForm.vue'),
      },
      {
        path: 'upload/list',
        name: 'tools.yes-phn.upload.table',
        meta: {
          breadcrumb: 'View Uploads',
        },
        component: () => import('@/components/tools/yes-phn/UploadTable.vue'),
      },
    ],
  },
  // admin routes
  {
    path: '/admin',
    name: 'admin',
    redirect: { name: 'admin.pending-invites' },
    component: () => import('@/views/Admin.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Pending Invites',
        },
        path: 'pending-invites',
        name: 'admin.pending-invites',
        component: () => import('@/components/admin/AdminPendingInviteTable.vue'),
      },
      {
        meta: {
          breadcrumb: 'Uploads',
        },
        path: 'uploads',
        name: 'admin.uploads',
        component: () => import('@/components/admin/AdminUpload.vue'),
      },
      {
        meta: {
          breadcrumb: 'Jobqueue',
        },
        path: 'jobqueue',
        name: 'admin.jobqueue',
        component: () => import('@/components/admin/AdminJobqueue.vue'),
      },
    ],
  },
  {
    path: '/reports',
    name: 'reports',
    redirect: { name: 'reports.standard_request' },
    component: () => import('@/views/Report.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Reports'
        },
        //path: "standard/system?:report_type&:start_date&:end_date&:fyPeriod&:fYear&:element&:level&{organisation_path:any}&:option&:data_source&:data_source_option&:episode_tags&:episode_tags_inclusion&:consent",
        path: 'standard/system',
        name: 'reports.standard_request',
        component: () => import('@/components/report/ReportStandard.vue'),
      },
      {
        meta: {
          breadcrumb: 'Wayback'
        },
        //path: "wayback/details?:report_type&:fin_year&:rpt_period&:start_date&:end_date&:level&{organisation_path:any}",
        path: "wayback/details",
        name: 'reports.wayback_request',
        component: () => import('@/components/report/ReportWayback.vue'),
      },
      {
        meta: {
          breadcrumb: 'Adhoc'
        },
        //path: "adhoc/details?:report_type&:end_date",
        path: "adhoc/details",
        name: 'reports.adhoc_request',
        component: () => import('@/components/report/ReportAdhoc.vue'),
      },
    ],
  },
  {
    path: '/reports/standard',
    name: 'reports.details',
    redirect: { name: 'reports.standard_details' },
    component: () => import('@/views/ReportDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Reports',
        },
        name: 'reports.standard_details',
        path: 'details',
        component: () => import('@/components/report/ReportDetails.vue'),
      },
    ],
  },
  {
    path: '/reports/wayback',
    name: 'reports.w_details',
    redirect: { name: 'reports.wayback_details' },
    component: () => import('@/views/ReportDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Reports',
        },
        name: 'reports.wayback_details',
        path: 'details',
        component: () => import('@/components/report/ReportDetails.vue'),
      },
    ],
  },
  {
    path: '/reports/adhoc',
    name: 'reports.a_details',
    redirect: { name: 'reports.adhoc_details' },
    component: () => import('@/views/ReportDetails.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Reports',
        },
        name: 'reports.adhoc_details',
        path: 'details',
        component: () => import('@/components/report/ReportDetails.vue'),
      },
    ],
  },
  {
    path: '/reports/:report/code',
    name: 'reports.code',
    redirect: { name: 'reports.view_code' },
    component: () => import('@/views/ReportCode.vue'),
    children: [
      {
        meta: {
          breadcrumb: 'Reports',
        },
        name: 'reports.view_code',
        path: '',
        component: () => import('@/components/report/ReportCode.vue'),
      },
    ],
  },
];

// So that we can detect the difference between 'no layout specified' and 'no
// route yet', make sure all the routes have a layout.
for (const route of routes) {
  route.meta        ||= { };
  route.meta.layout ||= 'DefaultLayout';
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

import { useUserStore } from '@/store/user';
import { useUnsavedAlertStore } from '@/store/unsavedAlert';
import { useErrorTrackingStore } from '@/store/errorTracking';

const publicStates = ["home", "login", "tools.slk",];

router.beforeEach(async (to, from) => {
  useUnsavedAlertStore().reset();
  const trackingStore = useErrorTrackingStore();
  const userStore = useUserStore();

  if ('login_token' in to.query) {
    // pingback url from streuth
    // consume login_token, delete login_token from the destination query
    // then navigate to the destination
    await userStore.fetch(true);
    const toRoute = cloneDeep(to);
    delete toRoute.query.login_token;
    return toRoute;
  }

  await userStore.fetch();

  if (userStore.loggedIn && to.name == 'states.login') {
    return { name: 'home' };
  }

  if (to.meta.noAuthRequired || to.name.endsWith('403')) {
    // public routes and route 403 can freely go
    if (from.name != to.name) {
        return true;
    }
    return false;
  }

  if (userStore.loggedIn) {
    //console.log('logged in');

    if (!userStore.isAdmin() && to.name.startsWith('admin')) {
      // non-admin user cannot access admin routes
      trackingStore.throwError("Forbidden State <!Auth.isAdmin>", { toState: to.name, auth: userStore.user });
      return { name: 'home' };
    }

    // special case: prevent users from edit their own roles
    if (to.name === 'users.details.roles.edit' && userStore.user.uuid === to.params.uuid) {
      return { name: 'users.details.roles' };
    }

    // special case: only allow to get to route 'activities' in certain situations
    if (to.name.startsWith('data-entry.activities')) {
      // skip the check for loading flag 
      await userStore.getOrganisations(true);
      const showActivities = userStore.userOrganisations.filter(
        x => typeof x.custodian_organisation_path !== 'undefined' && x.organisation_tags.indexOf('!nspt') >= 0
      ).length > 0;
      if (!showActivities) return { name: 'home' };
    }

    // special case: only allow to access '/organisations/list' if user has COs
    if (to.name === 'organisations.table') {
      const r = await api.get('/organisations?commissioning_organisations=1');
      if (!r.data.length) {
        return { name: 'organisations.provider_organisations.table' };
      }
    }

    if (userStore.isAdmin()) {
      // admin can see everything
      return true;
    }

    if (!userStore.hasAccessToRoute(to.name)) {
      // non-admin user access check
      trackingStore.throwError("Forbidden State <!hasAccessToState>", { toState: to.name, auth: userStore.user })
      return { name: 'states.errors.403' };
    }

    // get to here means users are allowed to go to the route
    return true;
  }

  return { name: 'home' };
});

import { useNotificationStore } from '@/store/notification';
router.afterEach((to, from) => {
  useNotificationStore().clear(to.name);
});

export default router;
